const NUMBER_OF_NOTES = 16;
const DEFAULT_BPM = 120;
const MIN_BPM = 0;
const MAX_BPM = 300;
const MIN_NB_STEPS = 1;
const MAX_NB_STEPS = 64;
const MAX_NB_INSTRUMENTS = 16;

export {
  NUMBER_OF_NOTES,
  DEFAULT_BPM,
  MIN_BPM,
  MAX_BPM,
  MIN_NB_STEPS,
  MAX_NB_STEPS,
  MAX_NB_INSTRUMENTS,
};
